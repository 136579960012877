<template>
  <client-only>
    <div class="atoms-comparison-table pt-[2.8125rem] pb-10 md:py-16 px-4 lg:px-0" :class="[bgClass]">
      <div class="max-w-wrapper-sm mx-auto" :class="[wrapperClass]">
        <!-- eslint-disable vue/no-v-html -->
        <p
          v-if="title"
          class="lg:text-center text-2xl md:text-3.5xl/12 mb-6 md:mb-[2.0625rem]"
          :class="[
            subTitle ? '!mb-4 md:!mb-4' : '',
          ]"
          v-html="title"
        >
        </p>

        <p
          v-if="subTitle"
          class="lg:text-center text-base lg:text-lg mb-6 md:mb-10"
          v-html="subTitle"
        >
        </p>

        <div class="w-full" :class="{'overflow-hidden mb-4': !devices.isMobile.value}">
          <div class="flex justify-center mx-auto mb-4 lg:mb-6">
            <MoleculesSelection
              v-model="tabsRef"
              ticker="square"
              type="radio"
              name="tabs"
              :class="{'w-full': devices.isMobile.value}"
              :cols="2"
              :mobile-cols="2"
              :size="devices.isMobile.value? 'xs' : 'sm'"
              :options="tabs"
            />
          </div>
          <div
            v-if="layout==='table'"
            class="single-table"
            :class="{'overflow-scroll pb-4': devices.isMobile.value}"
          >
            <table aria-describedby="Comparison table">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>

                  <th
                    v-for="(label, labelKey) in thead"
                    :key="labelKey"
                  >
                    {{ label }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, itemKey) in tbody"
                  :key="itemKey"
                >
                  <td
                    v-if="item === tbody[0]"
                    :rowspan="tbody.length"
                    class="bg-white"
                  >
                    {{ tabsRef }}
                  </td>
                  <td v-html="transformText(tbody[itemKey])"></td>

                  <td
                    v-for="(label, labelKey) in thead"

                    :key="labelKey"
                    class="text-center !font-bold"
                  >
                    {{ costs[tabsRef][label][item] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="layout==='two-table' && splitData" class="two-table">
            <div
              v-for="(tableKey, tableIndex) in splitData"
              :key="tableIndex"
              class="two-table__container"
            >
              <table
                aria-describedby="Comparison table"
                class="two-table__table"
              >
                <thead>
                  <tr>
                    <th>Age</th>

                    <th
                      v-for="(label, labelKey) in theadTwoTable(tableKey)"
                      :key="labelKey"
                      class="font-normal"
                      v-html="transformText(label)"
                    >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, itemKey) in Object.keys(tableKey)"
                    :key="itemKey"
                  >
                    <td>{{ item }}</td>
                    <td v-for="(data, dataIndex) in tableKey[item]" :key="dataIndex">
                      {{ data }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          v-if="footer"
          class="text-sm text-center"
          v-html="footer"
        >
        </div>
      </div>
    </div>
  </client-only>
</template>

<script setup>
defineOptions({
  name: 'AtomsHomeComparisonTableTabs'
})

const { devices } = deviceChecker()

const props = defineProps({
  layout: {
    type: String,
    default: 'table',
    validator: value => {
      return ['table', 'two-table'].includes(value)
    }
  },

  title: {
    type: String,
    default: ''
  },

  subTitle: {
    type: String,
    default: ''
  },

  footer: {
    type: String,
    default: ''
  },

  costs: {
    type: Object,
    default: () => {
      return {
        '$1 Million Cover': [
          {
            age: '45 Years Old',
            male: {
              daily: '$1.32',
              monthly: '$40.35'
            },
            female: {
              daily: '$1.08',
              monthly: '$32.99'
            }
          }
        ]
      }
    }
  },

  tabs: {
    type: Array,
    default: () => (['Tab 1', 'Tab 2'])
  },
  bgClass: {
    type: String,
    default: ''
  },
  wrapperClass: {
    type: String,
    default: ''
  }
})

const tabsRef = ref('$1 Million Cover')

const thead = computed(() => {
  const costs = props.costs
  const costsKeys = Object.keys(costs[tabsRef.value])

  return costsKeys
})

const tbody = computed(() => {
  const costs = props.costs
  const costsKeys = Object.keys(costs[tabsRef.value])

  return Object.keys(costs[tabsRef.value][costsKeys[0]])
})

const transformText = value => {
  let temp = value
  if (value) {
    temp = value.split(' - ')
    temp[0] = '<span class="font-bold">' + temp[0] + '</span>'
    temp = temp.join(' - ')
  }
  return temp
}

const splitData = computed(() => {
  const data = props.costs[tabsRef.value]
  const upData = {}
  const bottomData = {}

  for (const key in data) {
    upData[key] = {}
    bottomData[key] = {}

    const dataGroups = data[key]
    const dataKeys = Object.keys(dataGroups)
    const middleIndex = Math.ceil(dataKeys.length / 2)

    dataKeys.forEach((_, index) => {
      if (index < middleIndex) {
        upData[key] = {
          'Male - Daily Premium': dataGroups['Male - Daily Premium'],
          'Male - Monthly Premium': dataGroups['Male - Monthly Premium']
        }
      } else {
        bottomData[key] = {
          'Female - Daily Premium': dataGroups['Female - Daily Premium'],
          'Female - Monthly Premium': dataGroups['Female - Monthly Premium']
        }
      }
    })
  }

  return { upData, bottomData }
})

const theadTwoTable = data => {
  const dataKey = Object.keys(data)[0]
  const theadKey = Object.keys(data[dataKey])

  return theadKey
}
</script>

<style lang="scss" src="./index.scss" scoped></style>
